<style lang="css">
.center-md {
  font-size: 30px;
  padding: 0.4rem;
}

.order-info-md {
  text-align: left;
}

.order-info-md > div {
  line-height: 0.6rem;
}

.order-code-md > .time {
  float: right;
}

.bankinfo-center {
  background-color: #FFFFFF;
  margin: 0.3rem 0;
  border-radius: 8px;
  border: 0px;
  -moz-box-shadow: 2px 1px 4px #d2d2d2;
  -webkit-box-shadow: 2px 1px 4px #d2d2d2;
  box-shadow: 2px 1px 4px #d2d2d2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444')";
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444');
}

.bankinfo-item {
  padding: 0.35rem;
  border-bottom: 1px solid #c4c4c4;
  text-align: left;
}

.bankinfo-item > div {
  display: inline-block;
  float: right;
}

.copy-md {
  font-size: 0.20rem;
  color: #ffffff;
  margin-left: 0.18rem;
  padding: 0.1rem 0.25rem;
  text-align: center;
  font-weight: 500;
  background-color: #2a3dee;
  border-radius: 18px;
  text-decoration: none;
}

.orderCode-md {
  color: #000000;
  font-weight: 800;
}

.amount-md {
  color: #7b8dcc;
}

.paymentinfo-md {
  margin-bottom: 11px;
}

.payment-info-title {
  text-align: left;
}

.input-orderCode {
  position: relative;
  margin: 0.4rem 0;
}

.input-orderCode > label {
  position: absolute;
  top: 0.4rem;
  left: 0.25rem;
}

.inpu-orderCode {
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: right;
  padding: 0.4rem 0.2rem 0.4rem 3rem;
  font-size: 0.45rem;
  border: 0px;
  outline: none;
}

.sumbit-but {
  display: inline-block;
  width: 100%;
  font-size: 0.45rem;
  background-color: #2a3dee;
  padding: 0.25rem 0px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  border-radius: 8px;
}

.el-icon-upload2 {
}

.select-img > div {
  display: block;
  width: 2.8rem;
  border: 0.013333rem solid #cac8c8;
  height: 2.8rem;
  background-repeat: no-repeat;
  background-size: 0.8rem;
  background-position: 50%;
  outline: none;
}

.select-img > div > img {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.swal2-content {
  font-size: 0.425rem !important;
}

.swal2-actions {
  flex-wrap: nowrap !important;
  margin: 0.45em auto 0;
  overflow: hidden;
}

.swal2-actions > button {
  width: 50%;
  font-size: 0.525rem !important;
  text-align: center;
  /*border-radius: 0 !important;*/
  border-color: #ffffff;
}

.swal2-content {
  padding: 0.55rem 0 0 0;
}

.swal2-actions > button:nth-child(1) {
}

.swal2-actions > button:nth-child(2) {
  color: #a7a7a7 !important;
}

.swal2-styled {
  padding: 0.625em 0em;
  margin: 0;
  background-color: white !important;
}

.swal2-popup {
  padding: 0;
  overflow: hidden;
}

.swal2-title {
  font-size: 0.5em !important;
}

.swal2-icon.swal2-warning {
  width: 1rem;
  height: 1rem;
  font-size: 0.2rem !important;
  font-weight: 800 !important;
}

#swal2-content {
  text-align: left;
  padding: 0 0.5rem;
}

.okbut {
  border: 0;
  width: 100% !important;
  margin: 0 0.45rem;
  border-radius: 1rem !important;
  color: #ffffff !important;
  margin-bottom: 0.3rem;
  background: -webkit-gradient(linear, 80 -82, -48 bottom, from(#f27005), to(#ededed)) !important; /*Safari */
  background: -moz-linear-gradient(top, #f27005, #ededed) !important; /*Firefox*/
}

.layui-layer-loading .layui-layer-content {
  width: 1.4rem !important;
  height: 0.6rem !important;
}

.layui-layer-hui .layui-layer-content {
  padding: 0.46rem 0.433333rem !important;
  text-align: center;
}

.layui-layer-dialog .layui-layer-content {
  font-size: 0.386667rem !important;
}

.copy-log {
  display: inline-block;
  margin: 0 0.16rem;
  width: 0.52rem;
  height: 0.52rem;
  vertical-align: top;
  background: url('../../assets/images/copy-template.png');
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
}

.order-status-title {
  width: 100%;
  font-size: 0.55rem;
  padding: 0.52rem 0rem;
  text-align: center;
}

@font-face {
  font-family: 'iconfont';
  src: url('../../assets/font/iconfont.woff2?t=1657007420200') format('woff2'),
  url('../../assets/font/iconfont.woff?t=1657007420200') format('woff'),
  url('../../assets/font/iconfont.ttf?t=1657007420200') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 2.320rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<template>
  <div class="center">
    <div class="center-md">
      <header>
        <div class="order-status-title">payment result</div>
      </header>
      <!-- 订单号 订单时间 -->
      <header class="order-info-md">
        <div class="order-code-md order-item">
          <span
              class="order-id">Order Id: {{
              queryObject.payOrderId == undefined ? 'unknown' : queryObject.payOrderId
            }}</span>
          <span class="copy-log" @click="copyFn(queryObject.payOrderId)"></span>
        </div>
        <div class="order-time order-item">
          <span
              class="pay-order-time">Time: {{
              queryObject.createdAt == undefined ? 'unknown' : queryObject.createdAt
            }}</span>
        </div>
      </header>
      <div class="bankinfo-center">
        <div class="bankinfo-item">
          <label class="label-pl">payType</label>
          <div class="lable-var-md">
            <span class="orderCode-md">QR code payment</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl">orderStatus</label>
          <div class="lable-var-md">
            <span class="orderCode-md">{{
                queryObject.orderStatus == 0 ? 'Order to generate'
                    : queryObject.orderStatus == 1 ? 'during the payment'
                    : queryObject.orderStatus == 2 ? 'payment success'
                        : queryObject.orderStatus == 3 ? 'payment failure'
                            : queryObject.orderStatus == 4 ? 'Had withdrawn'
                                : queryObject.orderStatus == 5 ? 'refunded'
                                    : queryObject.orderStatus == 6 ? 'The order closed' : 'unknown'
              }}</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl" style="color: #a7a7a7;">Amount</label>
          <div class="lable-var-md">
            <span class="amount-md">{{queryObject.currency}} {{ queryObject.amount == undefined ? 'unknown' : queryObject.amount }}</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl" style="color: #a7a7a7;">practicalAmount</label>
          <div class="lable-var-md">
            <span class="amount-md">{{queryObject.currency}} {{ queryObject.practicalAmount == undefined ? 'unknown' : queryObject.practicalAmount }}</span>
          </div>
        </div>
      </div>

      <div class="paymentinfo-md">
        <div class="iconfont">
          {{
            queryObject.orderStatus == 0 ? '&#xe627;'
                : queryObject.orderStatus == 1 ? '&#xe600;'
                : queryObject.orderStatus == 2 ? '&#xe677;'
                    : queryObject.orderStatus == 3 ? '&#xe95e;'
                        : queryObject.orderStatus == 4 ? '&#xe63f;'
                            : queryObject.orderStatus == 5 ? '&#xe623;'
                                : queryObject.orderStatus == 6 ? '&#xe95e;' : '&#xe95e;'
          }}
        </div>
        <div class="orderCode-md">{{
            queryObject.orderStatus == 0 ? 'Order to generate'
                : queryObject.orderStatus == 1 ? 'during the payment'
                : queryObject.orderStatus == 2 ? 'payment success'
                    : queryObject.orderStatus == 3 ? 'payment failure'
                        : queryObject.orderStatus == 4 ? 'Had withdrawn'
                            : queryObject.orderStatus == 5 ? 'refunded'
                                : queryObject.orderStatus == 6 ? 'The order closed' : 'unknown'
          }}
        </div>
        <!--        <div class="payment-info-input-md" style=" background-color: #ffffff;">-->
        <!--          <span class="iconfont">&#xe61c;</span>-->
        <!--          <div class="input-orderCode">-->
        <!--            <label>UTR/Ref No</label>-->
        <!--            <div class="inpu-orderCode">sdfsdfsdfsdf{{ queryObject.bankOrderNo }}</div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
import {toRefs} from 'vue'
import {orderStatus} from '@/api/api'
import $ from 'jquery'

export default {
  data() {
    return {
      orderinfoimg: '',
      queryObject: {},
      file: null,
      inputOrderId: null,
      orderId: null
    }
  },
  components: {},
  mounted() {
    // 获取到订单号
    this.orderId = this.$route.params.id
    // 请求后台获取 获取个卡订单
    orderStatus(this.orderId).then(res => {
      this.queryObject = res
    }, function (err) {
      this.$alert('', err.msg, "error");
      $(".swal2-confirm").addClass("okbut")
    })
    $(".swal2-confirm").addClass("okbut")
  },
  methods: {
    copyFn(val) {
      // createElement() 方法通过指定名称创建一个元素
      var copyInput = document.createElement("input");
      //val是要复制的内容
      copyInput.setAttribute("value", val);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$alert("", "复制成功", "success");
          $(".swal2-confirm").addClass("okbut")
        }
      } catch {
        console.log("复制失败，请检查浏览器兼容");
      }
    },
  },
  copyFn(val) {
    // createElement() 方法通过指定名称创建一个元素
    var copyInput = document.createElement("input");
    //val是要复制的内容
    copyInput.setAttribute("value", val);
    document.body.appendChild(copyInput);
    copyInput.select();
    try {
      var copyed = document.execCommand("copy");
      if (copyed) {
        document.body.removeChild(copyInput);
        this.$alert("", "复制成功", "success");
        $(".swal2-confirm").addClass("okbut")
        console.log("复制成功");
      }
    } catch {
      console.log("复制失败，请检查浏览器兼容");
    }
  },
  setup() {
    // eslint-disable-next-line no-undef
    const state = reactive({
      loading: false,
      pageInfo: {
        page: 1,
        rows: 10,
        total: 0,
      }
    })
    // 最后return 出去
    return {
      ...toRefs(state),
    }
  }
}
</script>

